import Contact from './sections/Contact'
import Footer from './sections/Footer'
import Header from './sections/Header'
import Nosotros from './sections/Nosotros'
import Paquetes from './sections/Paquetes'
import Sucursales from './sections/Sucursales'

const App = () => {
  return (
    <div className='flex flex-col h-full justify-between antialiased bg-black dark:bg-slate-800'>
      <Header />
      <Nosotros />
      <Paquetes />
      <Sucursales />
      <Contact />
      <Footer />
    </div>
  )
}

export default App
