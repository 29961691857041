import ButtonReservar from '../components/ButtonReservar'
// images
import img1 from '../files/nosotros/img1.jpeg'
import img2 from '../files/nosotros/img2.jpeg'
import img3 from '../files/nosotros/img3.jpeg'

const Nosotros = () => {
  const Img = ({ file, width, height }) => (
    <img className='my-2 rounded' width={width} height={height} src={file} alt='' />
  )

  return (
    <div className='w-full bg-neutral-900/90'>
      <div className='mx-auto container'>
        <div className='grid sm:md:grid-cols-2 grid-cols-1'>
          <div className='m-5 flex items-center justify-center text-justify'>
            <div className='grid justify-items-center'>
              <p className='text-2xl text-muted text-white font-light my-5'>En nuestra Barber Shop ofrecemos una fusión moderna entre lo vintage y la elegancia: ¿Nuestra pasión? Ofrecer servicios para el cuidado del aspecto de los hombres con productos profesionales. Nuestra calidad, experiencia, servicio personalizado nos respaldan.</p>
              <ButtonReservar text='Agendar Cita' />
            </div>
          </div>
          <div className='p-5 grid justify-items-center'>
            <Img file={img1} width='320' height='120' />
            <div className='sm:md:mr-20'>
              <Img file={img2} width='320' height='70' />
            </div>
            <div className='sm:md:ml-20'>
              <Img file={img3} width='320' height='70' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nosotros
