// components
import Logo from '../components/Logo'
import ButtonReservar from '../components/ButtonReservar'
// media
import bg from '../files/paquetes/carrousel/img1.jpeg'

const Contact = () => {
  return (
    <div className='w-full h-2/3'>
      <div className='w-full h-full bg-cover bg-center' style={{ backgroundImage: `url(${bg})`, height: '65vh' }}>
        <div className='backdrop-brightness-50 bg-black/30 w-full h-full flex items-center justify-center'>
          <div className='grid sm:md:grid-cols-2 grid-cols-1 justify-items-center text-center'>
            <div className='grid justify-items-center'>
              <Logo width={170} height={75} />
              <p className='text-2xl font-bold text-white uppercase py-2'>CONTÁCTANOS</p>
              <p className='text-2xl font-light text-white capitalize pt-2 pb-8'>¿TE INTERESA FORMAR PARTE DE NUESTRO EQUIPO?</p>
            </div>
            <div className='grid justify-items-center p-5 backdrop-brightness-50 bg-black/60'>
              <p className='text-xl font-light text-white uppercase py-2'>LLENA EL SIGUIENTE FORMULARIO</p>
              <hr className='border-2 hover:border-6 border-amber-400 drop-shadow-xl w-20 mb-10' />

              <input type='text' placeholder='Nombre' className='input w-full my-2 text-sm max-w-xs p-2 rounded-2xl bg-slate-200/80 placeholder-slate-600/60' />
              <input type='text' placeholder='Apellido' className='input w-full my-2 text-sm max-w-xs p-2 rounded-2xl bg-slate-200/80 placeholder-slate-600/60' />
              <input type='tel' placeholder='Teléfono' className='input w-full my-2 text-sm max-w-xs p-2 rounded-2xl bg-slate-200/80 placeholder-slate-600/60' />
              <input type='mail' placeholder='Email' className='input w-full my-2 text-sm max-w-xs p-2 rounded-2xl bg-slate-200/80 placeholder-slate-600/60' />

              <p className='text-md font-light text-white uppercase mt-4 py-2'>Sucursal de tu interés</p>
              <select className='input w-full my-2 mb-4 text-sm max-w-xs p-2 rounded-2xl bg-slate-200/80 placeholder-slate-600/60'>
                <option value=''>Sucursal: Plaza Q7001 Centro Sur</option>
                <option value=''>Sucursal: Jacal</option>
                <option value=''>Sucursal: Plaza Q7001 Centro Sur</option>
              </select>
              <div className='my-4'>
                <ButtonReservar text='Enviar' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
