const ButtonReservar = ({ text, link = 'https://barberiaclub1978.agendapro.com/mx?fbclid=IwAR2yUXv_HcQ37rR42BXE0Ns98EKsosnzzblgfNHB-6s-BehAZwKzPTGVarU' }) => (
  <a
    className='bg-amber-500 hover:bg-amber-400 text-white font-bold py-2 px-4 rounded uppercase'
    href={link}
  >
    {text}
  </a>
)

export default ButtonReservar
