import { ReactComponent as IconFacebook } from '../files/icons/facebook.svg'
import { ReactComponent as IconInstagram } from '../files/icons/instagram.svg'

const Footer = () => {
  return (
    <div className='w-full h-2/3 bg-neutral-900/90'>
      <div className='h-full flex items-center justify-center'>
        <div className='grid justify-items-center m-10'>
          <p className='my-2 capitalize text-md text-white font-medium'>Franquicias disponibles, llave en mano al 442-278-25-72  / ventas@dmgroup.mx</p>
          <p className='my-2 uppercase text-xl text-white font-bold'>SÍGUENOS</p>
          <div className='flex items-center my-2'>
            <a href='https://www.facebook.com/Barberia-Apolo-Plaza-Q7001-Centro-Sur-110629018205090'>
              <IconFacebook className='h-10 w-10 text-blue-600/90' />
            </a>
            <IconInstagram className='h-10 w-10 text-pink-600/90' />
          </div>
          <p className='text-white my-2'>Barberia Apolo 2022 © </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
