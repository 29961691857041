import { ReactComponent as IconArrow } from '../files/icons/arrow-right.svg'
import dique from '../files/paquetes/dique.png'

const CardFirst = ({ darkMode, title, img, descripcion }) => {
  const CardBody = ({ darkMode, title, img, descripcion }) => (
    <div className='m-10'>
      <div className='flex items-baseline'>
        <IconArrow className='text-amber-400 h-10 w-10 border-4 border-amber-400 p-1 rounded-3xl' />
      </div>
      <div className='ml-10'>
        <div className='w-full h-full flex items-center justify-center'>
          <div className='grid justify-items-center'>
            {darkMode ? (<p className='my-2 text-light text-white uppercase'>{title}</p>) : (<p className='text-light text-black uppercase'>{title}</p>)}
            {img ? (<img className='my-5 mb-10' src={img} alt={title} />) : null}
            {darkMode ? (<p className='my-2 text-light text-white text-justify'>{descripcion}</p>) : (<p className='my-2 text-light text-black text-justify'>{descripcion}</p>)}
          </div>
        </div>
      </div>
    </div>
  )

  if (darkMode !== true) {
    return (
      <div className='w-full h-full bg-stone-100'>
        <CardBody darkMode={darkMode} title={title} img={img} descripcion={descripcion} />
      </div>
    )
  } else {
    return (
      <div className='w-full h-full bg-black'>
        <CardBody darkMode={darkMode} title={title} img={img} descripcion={descripcion} />
      </div>
    )
  }
}

const CardSecond = ({ darkMode, title, img, descripcion, items }) => {
  const CardBody = ({ title, descripcion, items }) => (
    <div className='m-10'>
      <div className='w-full flex items-center'>
        <img className='float-left' src={dique} width='60' height='60' alt='dique' />
        <p className='col-span-4 text-2xl text-bold text-black uppercase'>{title}</p>
      </div>
      <div className='w-full h-full flex items-center justify-center'>
        <div className='grid justify-items-center w-full'>
          <p className='my-2 text-light text-black text-justify py-4'>{descripcion}</p>
        </div>
      </div>
      <div className='w-full h-full'>
        {
          items.map((item, index) => (
            <div key={index} className='mb-5'>
              <div className='flex flex-row flex-nowrap justify-between w-full'>
                <h3 className='text-xl font-bold uppercase text-black'>{item.name}</h3>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-2xl text-amber-600'>.</p>
                <p className='text-xl text-black font-medium'>${item.cost}</p>
              </div>
              {item.description ? (<p className='text-sm text-black uppercase text-justify whitespace-normal'>{item.description}</p>) : null}
            </div>
          ))
        }
      </div>
    </div>
  )

  if (darkMode === true) {
    return (
      <div className='w-full h-full bg-stone-100'>
        <CardBody darkMode={darkMode} title={title} img={img} descripcion={descripcion} items={items} />
      </div>
    )
  } else {
    return (
      <div className='w-full h-full bg-white'>
        <CardBody darkMode={darkMode} title={title} img={img} descripcion={descripcion} items={items} />
      </div>
    )
  }
}

export { CardFirst, CardSecond }
