import { Carousel } from 'react-carousel-minimal'
// media paquetes
import paqueteApolo from '../files/paquetes/paquete_apolo.png'
import paqueteGriego from '../files/paquetes/paquete_griego.png'
import ritualClasico from '../files/paquetes/ritual_clasico.png'
import ritualDelineado from '../files/paquetes/ritual_delineado_barba.png'
// components cards
import { CardFirst, CardSecond } from '../components/CardsPaquetes'
// media carrousel
import img1 from '../files/paquetes/carrousel/img1.jpeg'
import img2 from '../files/paquetes/carrousel/img2.jpeg'
import img3 from '../files/paquetes/carrousel/img3.jpeg'

const data = [
  {
    title: 'Paquete Apolo $550 Corte + Barba + Cara + Bebida',
    img: paqueteApolo,
    description: 'Limpieza facial con jabón de carbón activado con vaporizador, aceite pre-shave, espuma caliente, bálsamo after shave, retiro de espinillas y puntos negros, ritual de toalla caliente, aplicación de tónico facial, mascarilla dorada de colágeno, lavado de cabello con shampoo y acondicionador, peinado con producto profesional, masaje relajante y Bebida de cortesía.',
    dark: false
  },
  {
    title: 'Paquete Griego $499 Corte + Barba + Bebida',
    img: paqueteGriego,
    description: 'Limpieza facial con jabón de carbón activado con vaporizador, aceite pre-shave, espuma caliente, bálsamo after shave, ritual de toalla caliente, aplicación de tónico facial, lavado de cabello con shampoo y acondicionador, peinado con producto profesional, masaje relajante y Bebida de cortesía.',
    dark: true
  },
  {
    title: 'CORTE DE CABELLO CORTE MÁSTER. . . $350',
    img: null,
    description: 'Limpieza facial con jabón de carbón activado con vaporizador, retiro de espinillas y puntos negros, mascarilla dorada de colágeno, ritual de toalla caliente, lavado de cabello con shampoo y acondicionador, peinado con producto profesional, masaje relajante y Bebida de cortesía',
    dark: true
  },
  {
    title: 'CORTE SENIOR. . .$259',
    img: null,
    description: 'Limpieza facial con jabón de carbón activado con vaporizador, lavado de cabello con shampoo y acondicionador, peinado con producto profesional, masaje relajante y Bebida de cortesía.',
    dark: false
  },
  {
    title: 'RITUAL CLÁSICO...$260',
    img: ritualClasico,
    description: 'Limpieza facial con jabón de carbón activado con vaporizador, aceite pre-shave, espuma caliente, bálsamo after shave, ritual de toalla caliente, aplicación de tónico facial, lavado de cabello con shampoo y acondicionador, peinado con producto profesional, masaje relajante y Bebida de cortesía.',
    dark: false
  },
  {
    title: 'RITUAL DELINEADO DE BARBA ...$150',
    img: ritualDelineado,
    description: 'Limpieza facial con jabón de carbón activado con vaporizador, aceite pre-shave, espuma caliente, bálsamo after shave, aplicación de tónico facial, lavado de cabello con shampoo y acondicionador, peinado con producto profesional, masaje relajante y Bebida de cortesía.',
    dark: true
  }
]

const dataSecond = [
  {
    title: 'Faciales',
    description: '',
    dark: false,
    items: [
      { name: 'Facial', cost: '250', description: 'Limpieza facial con jabón de carbón activado con vaporizador, ritual de toalla, exfoliación profunda con producto especializado, aplicación de tónico' },
      { name: 'Conoterapia', cost: '150', description: 'Limpieza profunda de oídos por medio de un cono encerado, aplicación de aceites terapéuticos, y masaje en puntos claves del cuerpo ¡Bebida de cortesía!' }
    ]
  },
  {
    title: 'TRATAMIENTO FACIAL',
    description: 'Limpieza facial con jabón de carbón activado con vaporizador, ritual de toalla, exfoliación profunda con producto especializado, aplicación de tónico',
    dark: true,
    items: [
      { name: 'Exfoliación', cost: '250', description: '' },
      { name: 'Hidratación', cost: '300', description: '' },
      { name: 'Contorno de ojos', cost: '200', description: '' }
    ]
  },
  {
    title: 'TRATAMIENTO CAPILAR',
    description: 'Limpieza facial con jabón de carbón activado con vaporizador, ritual de toalla, exfoliación profunda con producto especializado, aplicación de tónico',
    dark: true,
    items: [
      { name: 'SHAMPEELING', cost: '230', description: 'Lavado de cabello con shampoo y acondicionador, limpieza profunda y exfoliación del cuero cabelludo, masaje relajante y bebida de cortesía' },
      { name: 'Diagnóstico capilar', cost: '100', description: 'Diagnóstico capilar y de folículos con el uso de nuestra cámara capilar' }
    ]
  },
  {
    title: 'DEPILACIÓN',
    description: '',
    dark: false,
    items: [
      { name: 'Facial', cost: '250', description: 'Limpieza facial con jabón de carbón activado con vaporizador, ritual de toalla, exfoliación profunda con producto especializado, aplicación de tónico' },
      { name: 'conoterapia', cost: '150', description: 'Limpieza profunda de oídos por medio de un cono encerado, aplicación de aceites terapéuticos, y masaje en puntos claves del cuerpo ¡Bebida de cortesía!' }
    ]
  },
  {
    title: 'MANICURE Y PEDICURE',
    description: '',
    dark: false,
    items: [
      { name: 'Manicure y pedicure', cost: '550', description: '' },
      { name: 'pedicure', cost: '300', description: '' },
      { name: 'manicure', cost: '300', description: '' }
    ]
  },
  {
    title: 'TINTES',
    description: 'Limpieza facial con jabón de carbón activado con vaporizador, ritual de toalla, exfoliación profunda con producto especializado, aplicación de tónico',
    dark: true,
    items: [
      { name: 'exfoliación', cost: '250', description: '' },
      { name: 'hidratación', cost: '300', description: '' },
      { name: 'contorno de ojos', cost: '200', description: '' }
    ]
  }
]

const captionStyle = {
  fontSize: '2em',
  fontWeight: 'bold'
}
const slideNumberStyle = {
  fontSize: '20px',
  fontWeight: 'bold'
}

const Paquetes = () => (
  <div className='w-full bg-white'>
    <div className='mx-auto container text-center'>
      <p className='font-bold text-3xl m-5 uppercase mt-20'>
        Nuestros paquetes
      </p>
      <p className='text-lg font-light text-gray-600'>
        Fusión moderna entre lo vintage y la elegancia
      </p>

      <div className='grid sm:md:grid-cols-2 grid-cols-1 mt-20'>
        {
          data.map((item, index) => (
            <CardFirst key={index} darkMode={item.dark} title={item.title} img={item.img} descripcion={item.description} />
          ))

        }
        {
          dataSecond.map((item, index) => (
            <CardSecond key={index} darkMode={item.dark} title={item.title} descripcion={item.description} items={item.items} />
          ))

        }
      </div>
      <div className='container my-10 py-10'>
        <div style={{ textAlign: 'center' }}>
          <div style={{
            padding: '0 20px'
          }}
          >
            <Carousel
              data={[{ image: img1, caption: 'Confort' }, { image: img2, caption: 'Confort' }, { image: img3, caption: 'Confort' }]}
              time={4000}
              width='850px'
              height='500px'
              captionStyle={captionStyle}
              radius='10px'
              slideNumber
              slideNumberStyle={slideNumberStyle}
              captionPosition='bottom'
              automatic
              dots
              pauseIconColor='white'
              pauseIconSize='40px'
              slideBackgroundColor='darkgrey'
              slideImageFit='cover'
              thumbnails
              thumbnailWidth='100px'
              style={{
                textAlign: 'center',
                maxWidth: '850px',
                maxHeight: '500px',
                margin: '40px auto'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Paquetes
