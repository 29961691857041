const Sucursales = () => {
  return (
    <div className='w-full bg-neutral-900/90'>
      <div className='mx-auto container text-center my-10'>
        <p className='text-amber-500 font-bold text-3xl m-5 uppercase mt-20'>
          Sucursales
        </p>

        <div className='grid grid-cols-1 lg:grid-cols-3 lg:divide-x lg:divide-y-0 divide-y-2'>
          <div className='flex flex-col mx-auto container p-5'>
            <div className='flex mt-2 mb-10'>
              <div className='bg-amber-500 text-white rounded-3xl text-2xl p-1 h-10 w-10 shadow-amber-400 shadow-sm'>1</div>
            </div>
            <div className='flex flex-col mx-auto'>
              <p className='text-3xl text-white font-bold text-left py-2'>
                Sucursal: Plaza Q7001
              </p>
              <p className='text-2xl text-white font-medium text-left py-2'>
                Boulevard Bernardo Quintana 7001 , Santiago de Querétaro, México
              </p>
              <a className='text-xl text-white font-light text-left py-2' href='tel:+524427299635'>
                📱  : 442 729 9635
              </a>
              <p className='my-2 text-xl text-white font-light text-left py-2'>
                Horario: Lunes a viernes 10:00 hrs – 21:00 hrs y Sábados 11:00 hrs – 18:00 hrs
              </p>
              <div className='my-5'>
                <iframe
                  className='w-full'
                  title='Sucursal: Plaza Q7001 Centro Sur'
                  src='https://maps.google.com/maps?q=Boulevard%20Bernardo%20Quintana%207001%20%2C%20Santiago%20de%20Quer%C3%A9taro%2C%20M%C3%A9xico%20Tel%C3%A9fono%3A%20442%20729%209635&t=m&z=10&output=embed&iwloc=near'
                  frameborder='0'
                />
              </div>
            </div>
          </div>

          <div className='flex flex-col mx-auto container p-5'>
            <div className='flex mt-2 mb-10'>
              <div className='bg-amber-500 text-white rounded-3xl text-2xl p-1 h-10 w-10 shadow-amber-400 shadow-sm'>2</div>
            </div>
            <div className='flex flex-col mx-auto'>
              <p className='text-3xl text-white font-bold text-left py-2'>
                Sucursal: Jacal
              </p>
              <br />
              <p className='text-2xl text-white font-medium text-left py-2'>
                Boulevard Hacienda El Jacal 953, Santiago de Querétaro, México
              </p>
              <a className='text-xl text-white font-light text-left py-2' href='tel:+524423036392'>
                📱  : 442 303 6392
              </a>
              <p className='my-2 text-xl text-white font-light text-left py-2'>
                Horario: Lunes a viernes 08:00 hrs– 23:00 hrs y sábados 09:00 hrs – 18:00 hrs.
              </p>
              <div className='my-5 w-full'>
                <iframe
                  className='w-full'
                  title='Sucursal: Plaza Q7001 Centro Sur'
                  src='https://maps.google.com/maps?q=Boulevard%20Hacienda%20El%20Jacal%20953%2C%20Santiago%20de%20Quer%C3%A9taro%2C%20M%C3%A9xico&t=m&z=10&output=embed&iwloc=near'
                  frameborder='0'
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col mx-auto container p-5'>
            <div className='flex mt-2 mb-10'>
              <div className='bg-amber-500 text-white rounded-3xl text-2xl p-1 h-10 w-10 shadow-amber-400 shadow-sm'>3</div>
            </div>
            <div className='flex flex-col mx-auto'>
              <p className='text-3xl text-white font-bold text-left py-2'>
                Sucursal: Centro Sur
              </p>
              <p className='text-2xl text-white font-medium text-left py-2'>
                Boulevard Centro Sur 40 , Santiago de Querétaro, México
              </p>
              <a className='text-xl text-white font-light text-left py-2' href='tel:+524422228209'>
                📱  : 442 222 8209
              </a>
              <p className='my-2 text-xl text-white font-light text-left py-2'>
                Horario: Lunes a viernes 08:00 hrs – 23:00 hrs y sábados 11:00 hrs – 18:00 hrs
              </p>
              <div className='my-5'>
                <iframe
                  className='w-full'
                  title='Sucursal: Centro Sur'
                  src='https://maps.google.com/maps?q=Boulevard%20Centro%20Sur%2040%20%2C%20Santiago%20de%20Quer%C3%A9taro%2C%20M%C3%A9xico&t=m&z=10&output=embed&iwloc=near'
                  frameborder='0'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sucursales
