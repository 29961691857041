// components
import Logo from '../components/Logo'
import ButtonReservar from '../components/ButtonReservar'
// media
import bg from '../files/bg/header.jpeg'
import ondas from '../files/icons/ondas.png'

const Header = () => {
  return (
    <div className='w-full h-2/3'>
      <div className='w-full h-full bg-cover bg-center' style={{ backgroundImage: `url(${bg})`, height: '100vh' }}>
        <div className='backdrop-brightness-50 bg-black/30 w-full h-full flex items-center justify-center'>
          <div className='grid justify-items-center text-center'>
            <Logo width={150} height={75} />
            <p className='text-md font-light text-white uppercase py-2'>BARBERÍAS APOLO</p>
            <p className='text-2xl font-medium text-white capitalize pt-2 pb-8'>Calidad y profesionalismo a tu servicio</p>
            <p className='text-md font-light text-white uppercase py-2'>RESERVACIONES</p>
            <hr className='border-2 hover:border-6 border-amber-400 drop-shadow-xl w-20 mb-10' />
            <ButtonReservar text='RESERVAR EN LÍNEA' />
            <hr className='border-2 hover:border-6 border-amber-400 drop-shadow-xl w-20 my-10' />
            <img src={ondas} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
